body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  height: 80vh;
  overflow: auto;
}

.card:hover {
  background-color: #d2e7ff;
}

.dnd-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.col-title {
  text-align: center;
  padding: 3px;
  font-weight: bold;
}

.modal-90w {
  width: 90%;
}

.modal-80w {
  width: 80%;
}

.transaction-detail-quantities {
  max-height: 30vh;
  overflow-y: auto;
}

.transaction-detail-items {
  max-height: 30vh;
  overflow-y: auto;
}

.transaction-detail-history {
  max-height: 30vh;
  overflow-y: auto;
}

.dnd-column {
  height: 60vh;
  width: 220px;
  margin: 10px;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
}

.movable-item {
  border-radius: 10px;
  background-color: #dadada;
  height: 40px;
  width: 220px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}

.inputWithButton {
  position: relative;
  padding-left: 0rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.inputWithButton input {
  width: 70%;
  height: 25px;
  padding-right: 60px;
}

.inputWithButton button {
  position: absolute;
  right: 0;
  top: 5px;
}

.inputWithButton svg {
  margin-top: 6px;
}

.inputWithButton textarea {
  resize: none;
}

.no-border {
  border: none;
  outline: none;
}

.no-border:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.no-border:active {
  border: none;
  outline: none;
}

.showThis {
  opacity: 1;
}

.hideThis {
  opacity: 0;
}

.field-input {
  padding-bottom: 10px;
}

div.rct-tree-root {
  height: 100% !important;
}

.tree-container {
  height: 75vh !important;
  overflow-y: auto;
  width: 45%;
}

.template-modal {
  min-width: 70%;
}

.entity-modal {
  height: 100vh;
}

.infoIcon {
  padding-left: 2px;
}

.fieldList-container {
  height: 60vh;
  overflow-y: auto;
}

.field-list-child {
  padding-left: 20px;
}

.option-input {
  margin: 10px;
}

.main-div {
  height: 100vh;
}

.main-container {
  height: 80vh;
}

.tableContainer {
  overflow: scroll;
}

.detailsTableContainer {
  overflow: scroll;
}

.table {
  height: '100%';
  overflow-y: 'auto';
}

.MuiTablePagination-selectLabel {
  margin-top: 20px;
}

.MuiInputBase-root .MuiTablePagination-select {
  margin-top: 2px;
}

.pointer {
  cursor: pointer;
}

.preview {
  margin: 10px;
  max-width: 200px;
}

.img-comp {
  display: inline-block;
  position: relative;
}

.img-comp .image {
  display: block;
  width: 25px;
  height: 25px;
}

.img-comp .showImgCncl {
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 0;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.5s ease-out;
  background-color: white;
}

.img-comp .hideImgCncl {
  position: absolute;
  right: 10px;
  top: 10px;
  line-height: 0;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.5s ease-in;
  background-color: white;
}

.upload {
  display: inline-block;
  margin: top 5px;
  text-decoration: none;
}

.upload:hover {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 1s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

.upload .dropzone {
  /* display:inline-flex; */
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-top: 10px;
  max-width: 200px;
  block-size: 200px;
}

a .selected-file-wrapper {
  text-align: center;
}

a .selected-file {
  color: #000;
  font-weight: bold;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.MuiTableCell-root {
  padding: 10px !important;
}

div#main {
  height: 300px;
  width: 300px;
  position: absolute;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

div.circle {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid black;
  border-radius: 50%;
}

.links-container {
  /* background-color: lightblue;
border: black solid 1px; */
  position: relative;
  height: 100%;
  min-height: 80vh;
  min-width: 100%;
  width: 100%;
}

.link-node {
  position: absolute;
  min-width: 120px;
  max-width: 120px;
}

.container {
  position: relative;
  width: 100% !important;
  max-width: 90vw !important;
}

.links-panel {
  position: relative;
  height: 100%;
  /* min-height: 80vh; */
  max-height: 80vh;
  flex: 1;
  padding-left: 5vh;
  padding: 1vh 1vh 1vh 5vh;
  overflow-y: auto;
}

.hierarchy-container {
  width: 60vh;
  height: 80vh;
  max-height: 80vh;
  flex: 1;
}

.hierarchy-view {
  display: flex;
}

.hierarchy-search {
  display: inline-flex;
  align-items: center;
  padding-bottom: 8px;
}

.child-link {
  cursor: pointer;
  color: blue;
}

.child-link:hover {
  text-decoration: underline;
}

.link-node-selected {
  background-color: darkblue;
  border: solid black 5px;
}

.status-link {
  cursor: pointer;
  color: blue;
  font-weight: bold;
}

.status-link:hover {
  text-decoration: underline;
}

.history-timestamp {
  color: blue;
  min-width: 159px;
}

.history-user {
  color: blue;
}

.history-row {
  background-color: inherit;
}

.history-row:hover {
  background-color: rgb(232, 250, 255);
}

.history-field {
  font-weight: bold;
}

.history-value {
  overflow-wrap: anywhere;
}

p.MuiTablePagination-displayedRows {
  margin-top: 15px !important;
}

.table-header-cell-sort-label {
  flex-direction: 'row-reverse';
}

.row-icon {
  font-weight: normal;
}

.row-icon:hover {
  font-weight: bold;
}

.history-comment {
  font-style: italic;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 36px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 4px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #d2e7ff;
}

.inputButton {
  float: right;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 33px;
  width: 100%;
  min-width: 160px;
  margin-left: 1px;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  z-index: 2;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #d2e7ff;
}

.sticky-col {
  position: sticky !important;
  left: 0 !important;
  z-index: 900 !important;
  background-color: white !important;
  width: max-content !important;
  white-space: nowrap !important;
}

.sticky-first-col-header {
  width: max-content !important;
  min-width: max-content !important;
  white-space: nowrap !important;
  font-weight: bold !important;
  z-index: 910 !important;
}

.sticky-last-col {
  position: sticky !important;
  right: 0 !important;
  z-index: 900 !important;
  background-color: white !important;
  width: max-content !important;
  white-space: nowrap !important;
  text-align: center !important;
}

.table-row:hover {
  background-color: blue !important;
}

.search-table-header {
  width: max-content !important;
  min-width: max-content !important;
  white-space: nowrap !important;
  font-weight: bold !important;
  z-index: 901 !important;
}

.search-table-header-span {
  min-width: max-content !important;
}

.search-table-header-effect {
  background-color: white;
}

.search-table-header-effect:hover {
  background-color: rgb(232, 250, 255) !important;
}

.search-table-header-effect:hover .header-menu-icon {
  opacity: 1;
}

.search-table-col {
  width: max-content !important;
  min-width: max-content !important;
}

.row-menu-icon:hover {
  color: blue;
  box-shadow: darkblue;
}

.context-icon {
  margin-right: 8px !important;
}

.button-end-icon {
  margin-left: 8px !important;
}

.top-layer {
  z-index: 1000 !important;
}

.advanced-filters {
  white-space: nowrap;
  overflow-x: auto;
  text-align: center;
  display: flex;
  align-items: center;
}

.search-meta-value {
  background-color: rgb(240, 240, 240);
  padding: 4px;
  margin: 2px;
  border-radius: 2px;
  margin-right: 2px;
}

.fieldDetail {
  margin-bottom: 0.5rem;
}

.accordionSummary {
  /* background-color: rgb(207, 207, 207) !important; */
  max-height: 40px !important;
  min-height: 30px !important;
}

.accordion {
  /* background-color: rgb(207, 207, 207) !important; */
  max-height: 50vh !important;
  min-height: 30px !important;
  margin: 0px !important;
}

.accordionDetails .MuiTableContainer-root {
  overflow: hidden !important;
}

.accordionDetails .root {
  /* background-color: grey; */
  box-shadow: none !important;
  margin-left: 4%;

  /* background-color: rgb(207, 207, 207); */
}

.sourcesInfo {
  margin-left: 5% !important;
}

.accordionFooter .sourceadd {
  margin-left: 64% !important;
}

.accordionDetails .head {
  font-size: 0.8rem;
}

.accordionDetails .cell {
  background-color: rgb(255, 255, 255) !important;
  border-top: 1px dashed rgba(0, 0, 0, 0.6);
  font-size: 0.8rem;
}

.accordionDetails .summary {
  border: 1px solid rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
  font-weight: 700;
}

.materialEpoHistoryTable {
  position: static;
  min-height: 30px !important;
}

.accordionheader {
  float: right !important;
  margin-left: 70% !important;
}

.accordionFooter {
  font-size: 0.8rem;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.accordionFooter p {
  margin: 5px !important;
}

.maxQty {
  color: grey !important;
  font-size: 0.7rem !important;
  font-style: italic;
  margin-bottom: -3% !important;
  max-height: 30px;
}

.qtyinpt {
  margin-top: 3% !important;
}

.renameLabel {
  display: inline-block;
}

.renameInput {
  display: inline-block;
  font-size: inherit !important;
}

.inputBoxText {
  font-size: inherit !important;
}

.binary {
  margin-left: 0.2rem;
  width: 1.3em;
  height: 1.3rem;
}

.solidigm {
  background-color: #4f00b4;
}

.intel {
  background-color: #0071c5;
}

.intel-solidigm-gradient {
  background-image: linear-gradient(
    to right top,
    #0071c5,
    #0060c8,
    #004dc6,
    #3034c0,
    #4f00b4
  );
}

.intel-solidigm-gradient-qa {
  background-image: linear-gradient(to top, #cc9600, #0012b8);
}

.intel-solidigm-gradient-dev {
  background-image: linear-gradient(to top, #8b1500, #a78e00);
}

.active-search-row {
  background-color: red;
}

.autocomplete-filter {
  z-index: 902 !important;
}

.MuiAutocomplete-inputRoot {
  flex-wrap: nowrap !important;
}

.Mui-focused {
  flex-wrap: wrap !important;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 5px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
  border: 4px solid transparent;
}

.icon-btn {
  padding: 5px 6px 5px 6px !important;
}

.type-ahead .MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}

.type-ahead .MuiFormControl-root {
  border: 1px solid #ced4da !important;
  border-radius: 4px;
}

.type-ahead .MuiOutlinedInput-input {
  box-sizing: inherit !important;
}

.type-ahead .MuiOutlinedInput-root {
  padding: 0.375rem 0.75rem !important;
}

.type-ahead .MuiOutlinedInput-input {
  line-height: 1.5 !important;
  padding: 0 !important;
}

h6 {
  margin-bottom: 0.2rem !important;
}

.tree-head {
  margin-bottom: 0.1rem !important;
}

.widget-container {
  width: 90vw !important;
  height: 40vh !important;
}

.widget-section {
  /* background-color: lightblue; */
  border: 1px solid lightgray;
  border-radius: 3px;
  margin-bottom: 5px;
  margin-right: 20px;
  width: 48% !important;
  height: 95% !important;
  /* padding: 1% !important; */
  /* position: 'relative' !important;
  display: 'flex'!important;
  justify-content: 'center' !important; */
}

/* .widget-section:hover {
  background-color: rgb(195, 232, 255);
} */

.field-view {
  overflow-y: auto;
  height: 75%;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.carousel-image {
  text-align: center;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block !important;
  width: 75% !important;
}

.sources-grp {
  display: inline-block;
  justify-content: space-between;
  /* font-weight: bold; */
}

.sources-btn {
  border-radius: 5px !important;
  margin: 5px !important;
  font-size: 0.8rem;
}

.flow-list {
  width: 100%;
  height: 100%;
  background-color: lightblue;
  padding-bottom: 2px;
  border-radius: 3px;
  overflow-y: auto;
}

.flow-list-container {
  width: 100%;
  height: 70vh;
}

.flow-list-placeholder {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
}

.flow-item {
  display: flex;
  user-select: none;
  padding: 0.5rem;
  margin: 0 0 0.5rem 0;
  align-items: flex-start;
  align-content: flex-start;
  line-height: 1.5;
  border-radius: 3px;
  border: 1px solid rgb(102, 102, 102);
  background-color: white;
}

.flow-item-active {
  box-shadow:
    inset 0px 2px 4px rgba(0, 0, 0, 0.15),
    inset 0px 4px 8px rgba(0, 0, 0, 0.15),
    inset 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.flow-item:hover {
  color: white !important;
  background-color: #39c0ed !important;
  border: 1px solid #39c0ed !important;
  box-shadow: 2px 2px 6px black;
}

.flow-item-active:hover {
  color: white !important;
  background-color: lightblue !important;
  border: 1px solid lightblue !important;
  box-shadow:
    inset 0px 2px 4px rgba(0, 0, 0, 0.15),
    inset 0px 4px 8px rgba(0, 0, 0, 0.15),
    inset 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.flow-list-item:hover {
  color: white !important;
  background-color: #1266f1 !important;
  border: 1px solid #1266f1 !important;
  box-shadow: 2px 2px 6px black;
}

.flow-list-item-active {
  color: white !important;
  background-color: #1266f1 !important;
  border: 1px solid #1266f1 !important;
  box-shadow:
    inset 0px 2px 4px rgba(0, 0, 0, 0.15),
    inset 0px 4px 8px rgba(0, 0, 0, 0.15),
    inset 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.flow-list-item-active:hover {
  color: white !important;
  background-color: #1266f1 !important;
  border: 1px solid #1266f1 !important;
  box-shadow:
    inset 0px 2px 4px rgba(0, 0, 0, 0.15),
    inset 0px 4px 8px rgba(0, 0, 0, 0.15),
    inset 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.hidden {
  visibility: hidden;
}

ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.flow-item-list {
  padding-right: 10px !important;
}

.location-arrow {
  font-weight: bolder;
}

.type-ahead .MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}

.type-ahead .MuiFormControl-root {
  border: 1px solid #ced4da !important;
  border-radius: 4px;
}

.type-ahead .MuiOutlinedInput-input {
  box-sizing: inherit !important;
}

.type-ahead .MuiOutlinedInput-root {
  padding: 0.375rem 0.75rem !important;
}

.type-ahead .MuiOutlinedInput-input {
  line-height: 1.5 !important;
  padding: 0 !important;
}

.menu-link {
  text-decoration: none !important;
  color: inherit !important;
}

.table-header {
  font-weight: bold !important;
  white-space: nowrap;
}

.epo-flow-list {
  margin: 10px !important;
  padding: 1vw !important;
  max-height: 50vh !important;
  overflow-y: auto !important;
  background-color: lightblue;
  border-radius: 3px !important;
}

.css-hdw1oc {
  width: 0px !important;
}

.bulkInsertBtn {
  border-radius: 0.25rem !important;
  margin-left: 5px !important;
}

.addEntity {
  border-radius: 0.25rem !important;
}

.hierarchy-table-header-entities {
  font-weight: bold !important;
  width: 150px !important;
}

.hierarchy-table-header-links {
  font-weight: bold !important;
}

.export {
  margin: 0.2rem;
}

.css-hdw1oc {
  width: 0px !important;
}

.report-date label {
  font-size: 0.8em !important;
  font-weight: bold;
}

.report-date div {
  max-height: 30px !important;
  font-size: 0.8em !important;
  max-width: 150px !important;
}

.report-date input {
  /* max-height: 30px !important;
    font-size: 0.8em !important; */
  width: 60% !important;
  height: 0px !important;
}

.report-typePicker {
  width: 30% !important;
  max-height: 30px !important;
}

.report-type div {
  font-size: 0.8em !important;
  max-height: 30px !important;
}

.report-header {
  display: inline-flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}

.report-header h6 {
  padding: 2% 2% 0% 2% !important;
}

.showUtilFilter {
  display: flex !important;
  justify-content: end !important;
  padding: 2% 2% 0% 2% !important;
  opacity: 1;
  transition: opacity 0.5s ease-out;
  width: 80% !important;
}

.hideUtilFilter {
  display: flex !important;
  justify-content: end !important;
  padding: 2% 2% 0% 2% !important;
  opacity: 0;
  transition: opacity 0.5s ease-in;
  width: 80% !important;
}

.datePicker .Mui-focused {
  flex-wrap: nowrap !important;
}

.search-row {
  background-color: white;
  user-select: none;
}

.search-row-hover {
  background-color: rgb(232, 250, 255) !important;
  user-select: none;
}

.search-row-selected {
  background-color: lightblue !important;
  user-select: none;
}

.modal-90w {
  width: 90vw;
  max-width: 90vw;
  min-width: none;
}

.instruction-text {
  margin: 10px !important;
  font-weight: bold;
}

.question-text {
  margin: 10px !important;
}

.active-step {
  background-color: #b6edff;
}

.widget-section .graph {
  display: flex !important;
  /* justify-content: center !important; */
  position: relative !important;
  /* padding: 5% 8% 5% 5% !important; */
  height: 80% !important;
  width: 100% !important;
}

.legends {
  margin-left: 25% !important;
}

.pointer-right {
  margin-left: 10% !important;
}

.report-date-selector {
  display: flex !important;
  justify-content: end !important;
  padding: 2% 2% 0% 2% !important;
}

.report-text {
  width: 100% !important;
  margin-left: 10% !important;
}

.report-footer {
  position: absolute;
  top: 90% !important;
  left: 90% !important;
}

.report {
  display: flex !important;
}

.testers-report {
  width: 100% !important;
  height: 70vh !important;
}

.reset-pointer {
  font-size: 22px !important;
  cursor: pointer !important;
  align-self: center !important;
  color: dimgray;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}

.x.axis path {
  display: none;
}

.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 1.5px;
}

.gphBody {
  font: 10px sans-serif;
}

.tooltip {
  position: absolute;
  z-index: 10;
}

svg {
  max-width: 100% !important;
  max-height: 100% !important;
}

polyline {
  opacity: 0.3;
  stroke: black;
  stroke-width: 2px;
  fill: none;
}

.filter-trash-icon {
  min-height: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  width: 18px !important;
}

.logo {
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.8))
    drop-shadow(3px 5px 3px rgba(0, 0, 0, 0.5));
  /* drop-shadow(3px 5px 3px rgba(0, 0, 0, .5)); */
  transition: all 0.3s ease-in-out;
}

.logo:hover {
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.8))
    drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
  /* drop-shadow(3px 5px 3px rgba(0, 0, 0, .5)); */
  transform: translate(0px, 2px);
  transition: all 0.3s ease-in-out;
}

.welcome-text {
  padding-right: 15px;
  font-size: 60px;
  filter: drop-shadow(3px 5px 3px rgba(0, 0, 0, 0.5));
}

.welcome-sub-text {
  font-size: 25px;
  animation-name: animateIn;
  animation-duration: 1.5s;
  animation-delay: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.welcome-list {
  list-style: none;
  font-size: 20px;
  padding-top: 20px;
  /* padding-left: 20%;
  padding-right: 20%; */
  /* list-style-image: url('./elm.svg'); */
  list-style-position: inside;
  /* display: inline-flex;
  flex-wrap: wrap; */
  /* text-align: center; */
}

.welcome-list dt {
  margin: auto;
  text-align: center;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
  animation-name: animateIn;
  animation-duration: 2s;
  animation-delay: calc(var(--animation-order) * 500ms);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.welcome-list dd {
  padding-top: 10px;
  margin: auto;
  animation-name: animateIn;
  animation-duration: 2s;
  animation-delay: calc(var(--animation-order) * 500ms + 500ms);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

@keyframes animateIn {
  0% {
    opacity: 0;
    transform: scale(0.6) translateY(-20px);
  }

  100% {
    opacity: 1;
  }
}

.welcome-list dd::before {
  margin-right: 5px;
  display: inline-block;
  width: 13px;
  height: 16px;
  content: '';
  background: url('./elm3.png');
  background-size: 13px 16px;
}

.release-note-container {
  width: 100%;
  height: 80vh;
  overflow-y: auto;
}

.release-note-title {
  animation-name: none;
}

.release-note dt {
  margin: auto;
  text-align: center;
  font-size: 20px;
  padding-top: 20px;
  animation-name: none;
}

.release-note dd::before {
  content: '• ';
}

.release-note dd {
  margin: auto;
  font-size: 20px;
  padding-top: 5px;
}

.welcome-list dt:nth-child(n) {
  transition-delay: calc(n * 1s);
}

.welcome-list dd:hover {
  /* cursor: pointer; */
  /* filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, .2)); */
}

.logo-text {
  margin-top: 5px !important;
  filter: drop-shadow(3px 5px 3px rgba(0, 0, 0, 0.5));
}

.navbar .navbar-brand {
  padding-top: 0px;
}

.logo-bullet {
  transform: rotate(90deg);
}

h2 {
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
}

.welcome-container {
  animation-name: animateIn;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  align-items: center;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.upload-report .modal-dialog {
  max-width: 50% !important;
}

.upload-report .modal-content {
  max-height: 85vh;
  overflow: scroll;
}

.upload-report .modal-body {
  max-height: 80%;
  overflow: scroll;
}

.display {
  display: block !important;
  width: 100%;
}

.hide {
  display: none !important;
}

.spinner {
  float: left;
  margin-right: 5px;
  margin-top: 2px;
}

.upload-btns {
  float: right;
}

.type-entity {
  float: left;
  min-width: 92%;
}

.template-download {
  float: left;
  width: auto;
}

.bs-tooltip-top {
  z-index: 1056 !important;
}

.error-btn-grp1 {
  float: left;
}

.error-btn-grp2 {
  float: right;
}

.img-view {
  width: 100%;
  height: 37vh !important;
  overflow: hidden;
}

.img-view img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-position: center;
}

.epoSources {
  max-height: 60vh;
  overflow-y: auto;
}

.add-field .modal-body {
  max-height: 60vh !important;
  overflow: scroll !important;
}

.epo-content {
  width: 30% !important;
  max-width: 300px !important;
}

.epo-context {
  width: 100px !important;
  max-width: 300px !important;
}

.priority {
  width: 3% !important;
  max-width: 100px !important;
}

.epo-priority-header {
  width: 1% !important;
  max-width: 100px !important;
}

.epo-prioritized-header {
  width: 14% !important;
  max-width: 250px !important;
}

.phone-number {
  padding-bottom: 1px !important;
}
MuiInputBase-input.MuiOutlinedInput-input {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.phone-number .MuiOutlinedInput-root.Mui-focused {
  flex-wrap: nowrap !important;
}

.disabled-meta {
  overflow-wrap: anywhere;
}

.location-container {
  height: 100%;
}

.location-data {
  height: 75%;
  overflow-y: auto;
}

.col-header-group {
  color: blue;
}

.col-header {
  color: black;
}

.diff-loc-warn {
  margin-left: 4vw !important;
}

.diff-loc-warn p {
  color: red !important;
  font-style: italic;
  font-size: 0.9em;
}

.mat-table-rows-selected {
  margin-top: 1.1rem;
  margin-left: 1rem;
  font-size: 14px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: rgba(0, 0, 0, 0.87);
}

.modal-50w {
  min-width: 50%;
}

.modal-60w {
  min-width: 60%;
  max-height: 90vh;
}

.modal-70h {
  max-height: 70vh;
  overflow-y: auto;
}

/* Fixes overflow issue with MUI Autocomplete  */
.MuiAutocomplete-inputRoot {
  flex-wrap: wrap !important;
}

.tabular-report-table table {
  width: 100%;
  border: 1px solid;
}

.tabular-report-table th,
.tabular-report-table td {
  border: 1px solid;
}

.tabular-report-table-container {
  width: 100%;
  height: 50vh;
  overflow-y: auto;
}

.active-subscription {
  margin: 0px;
  padding: 4px 8px 4px 8px;
  color: white;
  background-color: green;
  border-radius: 6px;
}

.inactive-subscription {
  margin: 0px;
  padding: 4px 8px 4px 8px;
  color: white;
  background-color: gray;
  border-radius: 6px;
}

.pre-tag {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
}

.filter-save-icon {
  padding-left: 10px;
  color: #0d6efd;
  opacity: 0.3;
  cursor: pointer;
}

.filter-save-icon-disabled {
  padding-left: 10px;
  color: gray;
  opacity: 0.5;
}

.filter-save-icon:hover {
  padding-left: 10px;
  color: #0d6efd;
  opacity: 1;
  cursor: pointer;
}

.header-menu-icon {
  opacity: 0;
}

.header-menu-icon-active {
  opacity: 1;
  color: blue;
}
.header-filter-icon-active {
  opacity: 1;
  color: blue;
  float: inline-end;
}
.header-menu-icon:hover {
  opacity: 1;
  color: blue;
}

.modal-box {
  max-height: 50vh;
  max-width: 50%;
  overflow-y: auto;
  margin-right: 2vw;
  margin-left: 2vw;
  margin-top: 2vh;
}

.history-modal-box {
  max-height: 50vh;
  max-width: 100%;
  overflow-y: auto;
  margin-right: 2vw;
  margin-left: 2vw;
  margin-top: 2vh;
}

.detail-modal-box {
  max-height: 50vh;
  min-width: 40%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 2vw;
  margin-left: 2vw;
  margin-top: 2vh;
}
.item-header {
  display: flex;
  justify-content: space-between;
}
.filtered-header {
  color: #0d6efd !important;
}

.grouped-header {
  font-style: italic !important;
}

.help-bullet {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}

.header-dragging {
  z-index: 999 !important;
}

.header-div div {
  display: none !important;
}

.header-context {
  margin-left: -1rem;
  margin-top: 0.5rem;
}

.no-scroll {
  overflow: hidden;
  max-width: 100%;
  width: 100%;
}

.dragging-header {
  /* transform: translate3d(100px, 100px, 0); */
  font-weight: bolder;
}

.red-option {
  color: red !important;
}

.expired-api {
  color: red !important;
}

.MuiTableRow-hover:hover {
  background-color: rgb(232, 250, 255) !important;
}

.keyword-green {
  color: green !important;
}

.keyword-blue {
  color: blue !important;
}
.give td {
  padding-left: 0;
  max-height: 70px;
}

.give th {
  padding-left: 0;
}

.check-all {
  float: right;
}

.accept-list {
  float: left;
  min-width: 95%;
  max-width: 95%;
  max-height: 20vh;
  overflow-y: scroll;
}

.accept-list-ro {
  float: left;
  min-width: 95%;
  max-width: 98%;
  max-height: 20vh;
  overflow-y: scroll;
  display: flex !important;
  flex-wrap: wrap !important;
  color: grey;
}

.reject-list {
  float: left;
  min-width: 95%;
  max-height: 20vh;
  overflow-y: scroll;
}

.reject-list-ro {
  float: left;
  min-width: 95%;
  max-width: 98%;
  max-height: 20vh;
  overflow-y: scroll;
  display: flex !important;
  flex-wrap: wrap !important;
  color: grey;
}

.outer {
  width: 100%;
}

.outer .count {
  float: left;
}

.outer .confirm {
  float: right;
}

.outer .confirm button {
  margin-left: 10px;
}

.dev-active {
  color: yellow;
  filter: drop-shadow(0px 0px 8px white);
  size: 48;
}
.request-container {
  height: 73vh;
  overflow-y: auto;
}
.giveit-buildid-wrap {
  max-width: 15vw !important;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  margin: 2px;
}

.transactions-build-id {
  float: left;
  max-width: 15vw;
  display: flex !important;
  flex-wrap: wrap !important;
}

.give-table {
  max-height: 27vh;
  overflow-y: auto;
}
.request-create-container {
  max-height: 27vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.request-detail-container {
  max-height: 65vh;
  overflow-y: auto;
}
.footer-button {
  margin: 3px;
}

.image-rot-90 {
  transform: rotate(90deg);
}

.image-rot-180 {
  transform: rotate(180deg);
}

.image-rot-270 {
  transform: rotate(270deg);
}

.disabled-button {
  color: gray;
  opacity: 0.5;
}

.MuiBox-root {
  margin-left: 12px !important;
}

.MuiBox-root svg {
  margin-top: 3px !important;
  margin-right: 8px !important;
}

.giveId {
  vertical-align: baseline !important;
}

.badge-input:focus {
  color: white !important;
  mix-blend-mode: overlay !important;
  text-shadow: 0px 0px 0px #666;
  border: none !important;
  outline: none !important;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.badge-input {
  color: white !important;
  mix-blend-mode: overlay !important;
  text-shadow: 0px 0px 0px #666;
  border: none !important;
  outline: none !important;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.badge-read {
  width: 80%;
}
.new-badge {
  display: inline-flex !important;
  width: 100%;
  justify-content: space-between;
}

.react-flow .react-flow__handle {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #0004ff;
  transition: 0.2s;
}

.react-flow .react-flow__handle:hover {
  cursor: pointer;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #0004ff;
  transition: 0.2s;
}

.react-flow .react-flow__handle-left {
  left: -4px;
  transition: 0.2s;
}

.react-flow .react-flow__handle-right {
  right: -4px;
  transition: 0.2s;
}

.react-flow .react-flow__handle-left:hover {
  left: -5px;
  transition: 0.2s;
}

.react-flow .react-flow__handle-right:hover {
  right: -5px;
  transition: 0.2s;
}

.react-flow .react-flow__node {
  height: 30px;
  width: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-width: 1px;
  font-weight: 500;
}

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
  stroke-width: 1;
  color: blue;
  stroke: lightblue;
}

.flow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.custom-node {
  height: 30px;
  width: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid #0071c5;
  background-color: white;
  font-weight: 500;
  border-radius: 5px;
  flex: auto;
  flex-direction: column;
}

.custom-node label {
  display: block;
  color: black;
  font-size: 12px;
  display: block !important;
  cursor: grab;
}

.role-dot {
  width: 8px;
  height: 8px;
  align-items: center;
  border-radius: 8px;
  margin-right: 1px;
  margin-left: 1px;
  display: block !important;
}

.role-dot-legend {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 4px;
  margin-top: 8px;
}

.status-workflow-legend {
  position: absolute;
  right: 0;
  top: 0;
}

.status-workflow-options {
  position: absolute;
  right: 0;
  top: 0;
}

.status-workflow-title {
  position: absolute;
  left: 10px;
  top: 10px;
}

.edge-button {
  width: 50px;
  height: 12px;
  border-radius: 5px;
  align-items: center;
  background-color: white;
  border: 1px solid #0071c5;
  cursor: pointer;
}

.nested-menu-item p {
  padding-left: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    'sans-serif';
}

.dropdown-menu.show {
  width: max-content !important;
}
.dropdown-item.active {
  color: #fff !important;
  background-color: #0d6efd !important;
  text-decoration: none;
}

.activeRoute {
  color: #fff !important;
  background-color: #0d6efd !important;
  text-decoration: none;
}

.sitewide-banner {
  background-color: yellow;
  text-align: center;
}

.access-warning {
  padding-right: 15px;
  font-size: 60px;
}

.nav-tab-active {
  padding: 6px 16px 12px 16px;
  color: rgb(25, 118, 210);
  font-size: 17px;
  font-weight: 500;
  border-bottom: 2px solid;
  border-bottom-color: rgb(25, 118, 210);
  cursor: pointer;
}

.nav-tab {
  padding: 6px 16px 12px 16px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
}

.nav-tab:hover {
  color: #0a58ca !important;
}

.auth-container {
  height: 100vh;
  padding-top: 20vh;
}

.archive-note {
  padding-top: 5px;
  padding-bottom: 5px;
}

.wide-row-detail {
  max-height: 30vh;
  overflow-y: auto;
}

.classic-modal-box {
  max-height: 50vh;
  max-width: 100%;
  overflow-y: auto;
  margin-right: 2vw;
  margin-left: 2vw;
  margin-top: 2vh;
}

.pto-tab {
  width: 770px;
}

.box-container {
  float: right;
}

.boxno-pto {
  width: 60px;
}

.buildid-pto {
  width: 150px;
}

.srno-pto {
  width: 595px;
}

.action-all {
  width: 30px;
}

.action-pto {
  width: 20px;
}

.pto-tab-head {
  display: block;
}

.add-btn {
  padding: 2px !important;
}
.pto-tab-body {
  display: block;
  max-height: 300px;
  overflow-y: scroll;
}

.print {
  display: inline;
}

.fade-pto .modal-content {
  opacity: 0.9;
  transition: opacity 0.15s linear;
}
